import React from "react";
import { ReactSVG } from "react-svg";
import { labs } from "../../Assets/index";
import { Link } from "react-router-dom";

import "./FormPage.scss";

const FormPage = () => {
  return <div className="formpage">
    <div className="form">
      <ReactSVG src={labs} className="labs"/>
      <div className={`field`}>
        <input type="text"placeholder="USER"/>
      </div>
      <div className={`field`}>
        <input type="text"placeholder="PASSWORD"/>
      </div>
      <span className={`l-btn`}>
        <Link 
          to="/testing"
          rel="noreferrer noopener"
          className={`btn btn--primary`}>
            LOGIN
        </Link>     
      </span>
    </div>
  </div>;
};

export default FormPage;
