import React from "react";
import "./Passed.scss";
import { ReactSVG } from "react-svg";
import { passed } from "../../Assets";

const Passed = () => {
  return <div className="passed">
    <ReactSVG src={ passed }/>
    <h1>TEST PASSED!</h1>
  </div>;
};

export default Passed;
