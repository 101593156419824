import './App.scss';
import Index from './Slide/Index/Index';
import bgimg from './Assets/background.png';
import { Helmet } from 'react-helmet-async';
import { DataProvider } from "./DataContext";

function App() {
  return (<>
    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link href="https://fonts.googleapis.com/css2?family=ABeeZee&family=Teko:wght@300..700&display=swap" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&display=swap" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap" rel="stylesheet" />

      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5, user-scalable=0"/>
      <meta name="format-detection" content="telephone=no" />
    </Helmet>
    
    <DataProvider>
      <div className="app">
        <img src={ bgimg } alt="bg-image" className='bg-image' />
        <div className='application'>
          <Index />
        </div>
      </div>
    </DataProvider>
  </>
  );
}

export default App;
