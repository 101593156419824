import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import "./Research.scss";
import { DataContext } from '../../DataContext';


const Research = () => {
  const { selectedValue } = useContext( DataContext );

  const words = ['Transportability', 'Speed', 'Resistance', 'Customization', 'Gazebo Flash'];
  const targetWord = selectedValue;
  const animationDuration = 3000;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(true);

  useEffect(() => {
    const startTime = Date.now();

    if (isAnimating) {
      const interval = setInterval(() => {
        setCurrentIndex(prevIndex => {
          const nextIndex = Math.floor(Math.random() * words.length);

          if (Date.now() - startTime >= animationDuration) {
            setIsAnimating(false);
            return words.indexOf(targetWord);
          }

          return nextIndex;
        });
      }, 80); // Intervallo di aggiornamento

      return () => clearInterval(interval);
    }
  }, [isAnimating]);

  return ( <div className='research'>
      <div className="keyword">
        <Link to="/timer">{words[currentIndex]}</Link>
      </div>
    </div>
  );
}

export default Research;
