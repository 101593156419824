import React from "react";
import { Link } from "react-router-dom";
import "./Testing.scss";

const Testing = () => {
  return <div className="testing">
    <span className={`l-btn random`}>
      <Link 
        to="/search"
        rel="noreferrer noopener"
        className={`btn--random`}>
          RANDOM TESTING
      </Link>     
    </span>
  </div>;
};

export default Testing;
