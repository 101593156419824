import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "./Selector.scss";
import { DataContext } from "../../DataContext";

const Selector = () => {
  const { setSelectedValue } = useContext( DataContext );

  const handleClick = (value) => {
    setSelectedValue(value);
  };

  return <div className="selector">
    <span className={`l-btn press`}>
      <Link
        to="/form"
        rel="noreferrer noopener"
        className={`btn btn--primary`}
        onClick={() => handleClick("Transportability")}>
          Transportability
      </Link>     
    </span>
    <span className={`l-btn press`}>
      <Link 
        to="/form"
        rel="noreferrer noopener"
        className={`btn btn--primary`}
        onClick={() => handleClick("Speed")}>
          Speed
      </Link>     
    </span>
    <span className={`l-btn press`}>
      <Link 
        to="/form"
        rel="noreferrer noopener"
        className={`btn btn--primary`}
        onClick={() => handleClick("Resistance")}>
          Resistance
      </Link>     
    </span>
    <span className={`l-btn press`}>
      <Link 
        to="/form"
        rel="noreferrer noopener"
        className={`btn btn--primary`}
        onClick={() => handleClick("Customization")}>
          Customization
      </Link>     
    </span>
    <span className={`l-btn press`}>
      <Link 
        to="/form"
        rel="noreferrer noopener"
        className={`btn btn--primary`}
        onClick={() => handleClick("Gazebo Flash")}>
          Gazebo
      </Link>     
    </span>
  </div>;
};

export default Selector;
