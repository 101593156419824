import React from "react";

import "./Index.scss";
import FormPage from "../FormPage/FormPage";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import Testing from "../Testing/Testing";
import Timer from "../Timer/Timer";
import Research from "../Research/Research";
import { ReactSVG } from "react-svg";
import { logo } from "../../Assets";
import Selector from "../Selector/Selector";
import Passed from "../Passed/Passed";

const Index = () => {
  const location = useLocation();

  return <section className={`index ${location.pathname === "/form" ? '' : 'not-homepage'}`}>
    {location.pathname !== "/form" && <>
      <div className="labs">
        <p>GAZEBO_LABS</p>
      </div>
      <div className="logo">
        <Link to="/">
          <ReactSVG src={ logo } />
        </Link>
      </div>
    </>
    }

    <Routes location={location} key={location.pathname} >
      <Route path='/'>
        <Route index element={<Selector />} />
        <Route path="/form" element={<FormPage />} />
        <Route path="/testing" element={<Testing />}/>
        <Route path="/timer" element={<Timer />}/>
        <Route path="/search" element={<Research />}/>
        <Route path="/passed" element={<Passed />}/>
      </Route>
    </Routes>

    {location.pathname === "/timer" && <>
      {/* <span className={`l-btn press`}>
        <Link 
          to="#"
          rel="noreferrer noopener"
          className={`btn btn--primary`}>
            STRESS TEST
        </Link>     
      </span> */}
      <span className={`l-btn squad`}>
        <Link 
          to="/passed"
          rel="noreferrer noopener"
          className={`btn btn--primary`}>
            TEST RESULTS
        </Link>     
      </span>
    </>
    }
  </section>;
};

export default Index;
