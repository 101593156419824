import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useTimer } from 'react-use-precision-timer';
import "./Timer.scss";
import { ReactSVG } from 'react-svg';
import { pause, play, reset } from '../../Assets';
import { DataContext } from '../../DataContext';

const Timer = () => {
  const { selectedValue } = useContext(DataContext);

  let initialTime;
  let maxTime;

  if (selectedValue === 'Transportability') {
    initialTime = 30000; // 30 secondi in millisecondi
    maxTime = 0;
  } else if (selectedValue === 'Customization') {
    initialTime = 86400000; // 24 ore in millisecondi
    maxTime = 0;
  } else {
    initialTime = 0; // 0 secondi in millisecondi
    maxTime = 30000;
  }

  const [time, setTime] = useState(initialTime);
  const [isRunning, setIsRunning] = useState(false);
  const [resetHidden, setResetHidden] = useState(0);

  const callback = useCallback(() => {
    setTime(prev => {
      if (selectedValue === 'Customization' || selectedValue === 'Transportability') {
        return Math.max(prev - 10, maxTime);
      } else {
        return Math.min(prev + 10, maxTime);
      }
    });
  }, [selectedValue, maxTime]);

  const timer = useTimer({ delay: 10 }, callback);

  useEffect(() => {
    if (timer.isRunning()) {
      const interval = setInterval(() => {
        setTime(timer.getElapsedRunningTime());
      }, 10);
      return () => clearInterval(interval);
    }
  }, [timer]);

  useEffect(() => {
    if ((selectedValue === 'Transportability' && time === maxTime) || 
        (selectedValue === 'Customization' && time === maxTime) || 
        (selectedValue !== 'Transportability' && selectedValue !== 'Customization' && time === maxTime)) {
      timer.stop();
      setIsRunning(false);
    }
  }, [time, timer, selectedValue, maxTime]);

  const startTimer = () => {
    if (!isRunning) {
      timer.start();
      setIsRunning(true);
    }
  };

  const stopTimer = () => {
    timer.stop();
    setIsRunning(false);
  };

  const resetTimer = () => {
    stopTimer();
    setTime(initialTime);
    setResetHidden(0);
  };
  
  const resetTimerHidden = () => {
    stopTimer();
    switch (resetHidden) {
      case 0:
        setTime(75000000);
        setResetHidden(resetHidden + 1);
        break;
      case 1:
        setTime(53200000);
        setResetHidden(resetHidden + 1);
        break;
      case 2:
        setTime(32300000);
        setResetHidden(resetHidden + 1);
        break;
      case 3:
        setTime(25000000);
        setResetHidden(resetHidden + 1);
        break;
      case 4:
        setTime(10300000);
        setResetHidden(resetHidden + 1);
        break;
      case 5:
        setTime(750000);
        setResetHidden(0);
        break;
      default:
        return null;
    }
  };

  const hours = Math.floor(time / 3600000).toString().padStart(2, '0');
  const minutes = Math.floor((time % 3600000) / 60000).toString().padStart(2, '0');
  const seconds = Math.floor((time % 60000) / 1000).toString().padStart(2, '0');
  const milliseconds = Math.floor((time % 1000) / 10).toString().padStart(2, '0');

  return (<>
    <div className="timer">
      <div className='time'>
        { selectedValue === 'Customization' && <span className='ore'>{hours}:</span>}
        <span className='minuti'>{minutes}</span>:
        <span className='secondi'>{seconds}</span>:
        <span className='millisecondi'>{milliseconds}</span>
      </div>
      <div className="controls">
        <ReactSVG src={play} onClick={startTimer} />
        <ReactSVG src={pause} onClick={stopTimer} />
        <ReactSVG src={reset} onClick={resetTimer} />
      </div>
    </div>
    { selectedValue === 'Customization' && <div className="hidden" onClick={resetTimerHidden}></div> }
  </>
  );
}

export default Timer;
